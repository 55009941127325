import {
  formatDistance,
  formatRelative,
  fromUnixTime,
  getUnixTime,
  subDays,
} from "date-fns"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../forms/button"
import InputText from "../../forms/inputText"

import Layout from "../../layout"
import SEO from "../../seo"

type TimeUnits = {
  date: Date
  epoch: number
  epochSecType: string
  formatRelative: string
}

const TimePage = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const [timeNow, setTimeNow] = useState<string>(
    new Date().toLocaleTimeString()
  )
  const [convert, setConvert] = useState<Boolean>(false)
  const [time, setTime] = useState<TimeUnits>({
    date: new Date(),
    epoch: getUnixTime(new Date()),
    epochSecType: "seconds",
    formatRelative: "now",
  })

  const setTimeData = data => {
    // hack to solve
    console.log(data)

    const timeInput = data.timeInput
    let timeInputValue = data.timeInput
    let timeInputValueAsNumber = +timeInputValue

    if (isNaN(timeInputValueAsNumber)) {
      alert("Please enter a valid number")
      return
    } else {
      const numberlength = timeInputValue.length
      let epochSecType = "seconds"

      console.log(numberlength)

      // takes into account other time units
      if (numberlength > 16) {
        const end = numberlength - 9
        timeInputValue = timeInputValue.substring(0, end)
        epochSecType = "nanoseconds"
      } else if (numberlength > 14) {
        const end = numberlength - 6
        timeInputValue = timeInputValue.substring(0, end)
        epochSecType = "microseconds"
      } else if (numberlength > 11) {
        const end = numberlength - 3
        timeInputValue = timeInputValue.substring(0, end)
        epochSecType = "milliseconds"
      }
      timeInputValueAsNumber = +timeInputValue

      const date = fromUnixTime(timeInputValueAsNumber)
      const epoch = timeInputValueAsNumber
      const formatRelativeVar = formatDistance(subDays(date, 0), new Date(), {
        addSuffix: true,
      })

      setTime({
        date: date,
        epoch: epoch,
        epochSecType: epochSecType,
        formatRelative: formatRelativeVar,
      })

      setConvert(true)
    }
  }

  const setTimeDataNow = () => {
    setTime({
      date: new Date(),
      epoch: getUnixTime(new Date()),
      epochSecType: "seconds",
      formatRelative: "now",
    })

    setConvert(false)
  }

  return (
    <Layout>
      <SEO title="Time" />
      <h2>Fecha y hora actual en formato local</h2>
      <p>Convierte tu fecha epoch o unix en fecha formato local.</p>
      <form onSubmit={handleSubmit(setTimeData)}>
        <div className="my-4">
          <InputText
            type="number"
            placeholder="Ingresa fecha o tiempo en epoch"
            // register send validation message
            register={register("timeInput", { required: true })}
            label="Fecha en epoch"
            error={errors.timeInput}
          />
          {console.log(errors)}
        </div>
        <div className="grid gap-4 mb-4 sm:grid-cols-1 md:grid-cols-2">
          <Button
            onClick={handleSubmit(setTimeData)}
            label="Convertir epoch"
            color="blue"
          />
          <Button onClick={setTimeDataNow} label="Hora actual" color="blue" />
        </div>
      </form>
      {convert ? <p>Se convirtio epoch en {time.epochSecType}</p> : <></>}
      <ul>
        <li>
          <strong>Fecha:</strong> {time.date.toString()}
        </li>
        <li>
          <strong>Fecha epoch:</strong> {time.epoch} (seconds)
        </li>
        <li>
          <strong>Relativo:</strong> {time.formatRelative}
        </li>
      </ul>
    </Layout>
  )
}

export default TimePage
